import * as React from 'react';
import { Meta } from '../components/Meta';
import { Layout } from '../components/Layout';
import { OurWork } from '../components/OurWork';
import { AboutUs } from '../components/AboutUs';
import { OurClients } from '../components/OurClients';
import { ServicesSection } from '../components/ServicesSection';
import { Achievements } from '../components/Achievements';
import { HomeHeroSection } from '../components/HomeHeroSection';

export default function MainPage() {
  return (
    <Layout>
      <Meta route="home" />
      <HomeHeroSection />
      <ServicesSection />
      <Achievements />
      <OurWork />
      <AboutUs />
      <OurClients />
    </Layout>
  );
}
