import * as React from 'react';
import cn from 'classnames';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';

import LeafLeft from '../images/leaf-left.svg';
import LeafRight from '../images/leaf-right.svg';

const achievements = [
  {
    title1: '20',
    title2: 'years',
    subtitle: 'on Nantucket Island',
    additionalInfo: 'We are familiar with the unique challenges of landscaping on Nantucket.',
    img: LeafLeft,
  },
  {
    title1: '10',
    title2: 'specialists',
    subtitle: 'in landscape design',
    additionalInfo: 'We are a team of talented outdoor artisans & craftsmen.',
    img: LeafRight,
  },
  {
    title1: '4',
    title2: 'gardening',
    subtitle: 'experts',
    additionalInfo: 'We are equipped to plant & help you maintain a huge variety of greenery.',
  },
];

const useStyles = makeStyles((theme) => ({
  achievementsSection: {
    textAlign: 'center',
    paddingRight: '15%',
    paddingLeft: '15%',
    [theme.breakpoints.down(1200)]: {
      paddingRight: '6%',
      paddingLeft: '6%',
      marginBottom: -30,
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  achievementsSectionContainer: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  achievementsSectionContent: {
    maxWidth: 300,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 40,
      maxWidth: 200,
    },
  },
  secondaryText: {
    width: '85%',
    margin: 'auto',
  },
  title: {
    color: theme.palette.primary.main,
    '&.title1': {
      fontSize: 100,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: 65,
      },
    },
    '&.title2': {
      fontSize: 30,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },
  },
  subtitle: {
    fontSize: 26,
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
      paddingBottom: 24,
    },
  },
  additionalInfo: {
    fontSize: 18,
    lineHeight: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '20px',
    },
  },
}));

export function Achievements() {
  const classes = useStyles();

  return (
    <div className={classes.achievementsSection}>
      <Typography variant="h1">Beautiful, Sustainable Lawns and Gardens for Nantucket</Typography>
      <Box mt={3} />
      <Typography variant="h5" color="textSecondary" className={classes.secondaryText}>
        To us at Merlos, landscaping is an exercise in artistry & craftsmanship. Our work harnesses the distinct coastal
        ambiance found on Nantucket and uses it to beautify your home in ways that meet your unique needs. We execute
        every client's vision with precision and creativity, making meaningful outdoor spaces that are full of value,
        color, and character. And because the rich, natural beauty of Nantucket is worth preserving, we are dedicated to
        using sustainable solutions on every project.
      </Typography>
      <Box mt={10} />
      <Grid container justify="space-between" className={classes.achievementsSectionContainer}>
        {achievements.map(({ title1, title2, subtitle, additionalInfo, img }, i) => (
          <React.Fragment key={i}>
            <Grid item md={3} xs={12} className={classes.achievementsSectionContent}>
              <Typography variant="h2" className={cn(classes.title, 'title1')}>
                {title1}
              </Typography>
              <Typography variant="h4" className={cn(classes.title, 'title2')}>
                {title2}
              </Typography>
              <Typography className={classes.subtitle}>{subtitle}</Typography>
              <Typography className={classes.additionalInfo}>{additionalInfo}</Typography>
            </Grid>
            {img && (
              <Box component={Grid} item display={{ xs: 'none', md: 'block' }}>
                <img src={img} alt={img} />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </div>
  );
}
