import * as React from 'react';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import VectorSection4Object from '../images/vector-section4-object.webp';
import LayerSection4 from '../images/layer-section4.webp';
import Crafting from '../images/crafting.webp';
import Section4 from '../images/section4-image.webp';

const useStyles = makeStyles((theme) => ({
  aboutUsSection: {
    paddingRight: '15%',
    paddingLeft: '15%',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '6%',
      paddingLeft: '6%',
    },
  },
  aboutUsSectionAboutUs: {
    backgroundImage: `url(${VectorSection4Object})`,
    backgroundSize: 324,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left 30%',
    paddingRight: 100,
    paddingBottom: 50,
    [theme.breakpoints.down('xs')]: {
      backgroundSize: 180,
      marginBottom: 25,
      paddingBottom: 0,
      paddingRight: 50,
    },
  },
  responsiveText15: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  responsiveText18: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  reasonBox: {
    border: `1px solid ${theme.palette.colors.lightGreyColor2}`,
    padding: 22,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      padding: '11px 22px',
      marginBottom: 16,
    },
  },
  reasonHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
      lineHeight: '32px',
    },
  },
  craftingContainer: {
    backgroundColor: theme.palette.primary.main,
    marginTop: -5,
    color: theme.palette.colors.whiteColor,
    textAlign: 'center',
  },
  craftingImage: {
    width: 120,
    [theme.breakpoints.down('xs')]: {
      width: 80,
    },
  },
}));

export function AboutUs() {
  const classes = useStyles();

  return (
    <div className={classes.aboutUsSection}>
      <Box pt={{ xs: 6, md: 14 }} />

      <Grid container>
        <Grid item md={6} xs={12} className={classes.aboutUsSectionAboutUs}>
          <Typography variant="h3">About us</Typography>
          <Typography variant="h5" className={classes.responsiveText15}>
            Since 2001, all of us at Merlos Landscaping have been re-imagining Nantucket’s outdoor spaces with ingenuity
            and careful artistry. As a family owned business, we’ve created a close-knit culture of landscapers that
            don’t just construct or build - but express and elevate the land’s natural beauty. From full-service lawn
            care to off-beat garden designs, we aim to make a statement with every project, and always work within
            Nantucket's many micro-environments to promote healthy spaces that last. Conception, installation, long-term
            maintenance - no matter which step of the process we’re on, we’re always working to complete your vision and
            enrich Nantucket's native environment.
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <img src={LayerSection4} alt="LayerSection4" width="100%" />
          <Grid container>
            <Grid item md={6} xs={12}>
              <Box mt={3} mr={{ xs: 0, md: 4 }} mb={{ xs: 5, md: 0 }}>
                <Box mt={3} />
                <Typography variant="h6" color="textSecondary">
                  Merlos Landscaping has been providing landscaping services on Nantucket since 2001. Our expert lawn
                  care specialists and garden experts have decades of experience with the nature of the island.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12} className={classes.craftingContainer}>
              <Box pt={{ xs: '10%', md: '17%' }} pb={{ xs: '10%', md: '28%' }} px={{ xs: '8%', md: '10%' }}>
                <img src={Crafting} alt="Crafting" className={classes.craftingImage} />
                <Box pt={{ xs: 3, md: 5 }} />
                <Typography variant="h5" className={classes.responsiveText18}>
                  Craftsmanship
                </Typography>
                <Box pt={{ xs: 3, md: 5 }} />
                <Typography variant="h6">
                  Merlos is stunning and sustainable landscaping done right. We take our time to map-out a concept that
                  not only compliments your home, but compliments the environment. Utilizing local vegetation, careful
                  plant placement, and other methods, we preserve Nantucket’s ecosystems while lending an effortless,
                  refined charm to your lawn or garden.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box pt={{ xs: 5, md: 14 }} />

      <Typography variant="h3" style={{ textAlign: 'center' }}>
        Why Choose Us?
      </Typography>
      <Box pt={4} />
      <Grid container>
        <Box component={Grid} item md={6} xs={12} pr={{ xs: 0, md: 4 }} pb={{ xs: 2, md: 0 }}>
          <img src={Section4} alt="Section4" width="100%" />
        </Box>
        <Box component={Grid} item md={6} xs={12} pl={{ xs: 0, md: 4 }} container>
          <Box width="100%" component={Grid} item className={classes.reasonBox}>
            <Typography variant="h5" color="primary" className={classes.reasonHeader}>
              We Are Creative
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Delivering on your landscape, in all its detail, can take a lot of coordination. There are materials and
              equipment to have on hand, scheduling the right craftspeople and trades, keeping everything running
              smoothly and, of course, always keeping you abreast with our progress.
            </Typography>
          </Box>
          {['Outdoor Originals', 'We Are Always Improving', 'Quality Commitment '].map((el) => (
            <Box key={el} width="100%" component={Grid} item className={classes.reasonBox}>
              <Typography variant="h5">{el}</Typography>
            </Box>
          ))}
        </Box>
      </Grid>
      <Box pt={{ xs: 2, md: 14 }} />
    </div>
  );
}
