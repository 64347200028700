import * as React from 'react';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';

import GrassImage from '../images/services-grass.webp';
import LawnMover from '../images/lawn-mower.webp';
import Farming from '../images/farming.webp';
import Brickwall from '../images/brickwall.webp';
import Events from '../images/events.webp';

const services = [
  {
    title: 'Lawns',
    subtitle: 'Year-round, full service lawn care',
    additionalInfo: 'Read more',
    img: LawnMover,
    imgWidth: 132,
  },
  {
    title: 'Gardens',
    subtitle: 'Expert plant selection & installation',
    additionalInfo: 'Read more',
    img: Farming,
    imgWidth: 118,
  },
  {
    title: 'Stonework',
    subtitle: 'Customized patios, walkways, & driveways',
    additionalInfo: 'Read more',
    img: Brickwall,
    imgWidth: 117,
  },
  {
    title: 'Trees & Hedges',
    subtitle: 'Long-term tree & hedge maintenance',
    additionalInfo: 'Read more',
    img: Events,
    imgWidth: 108,
  },
];

const useStyles = makeStyles((theme) => ({
  servicesSection: {
    height: 'auto',
    width: 1281,
    backgroundImage: `url(${GrassImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: '-200px auto 130px',
    boxShadow: '0 0 80px 0 rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down(1700)]: {
      maxWidth: '90%',
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      backgroundImage: 'none',
      margin: '50px auto',
    },
  },
  servicesSectionItem: {
    textAlign: 'center',
    padding: 40,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
    },
  },
  grassImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50%',
    },
  },
}));

export function ServicesSection() {
  const classes = useStyles();

  return (
    <div className={classes.servicesSection}>
      <Grid container alignItems="baseline">
        {services.map(({ img, imgWidth, title, subtitle, additionalInfo }, i) => (
          <Grid item key={i} sm={3} xs={6} container className={classes.servicesSectionItem}>
            <Grid item xs={12}>
              <img src={img} alt={title} width={imgWidth} className={classes.grassImage} />
            </Grid>
            <Box mt={{ xs: 4, md: 5 }} width="100%" />
            <Grid item xs={12}>
              <Typography variant="h4">{title}</Typography>
            </Grid>
            <Box mt={{ xs: 2, md: 3 }} width="100%" />
            <Grid item xs={12}>
              <Typography variant="subtitle1">{subtitle}</Typography>
            </Grid>
            <Box mt={1} width="100%" />
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary" className={classes.underline}>
                {additionalInfo}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
