import * as React from 'react';
import Helmet from 'react-helmet';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  ourWork: {
    backgroundColor: theme.palette.colors.lightPinkColor,
    textAlign: 'center',
    paddingRight: '15%',
    paddingLeft: '15%',
    paddingTop: 150,
    [theme.breakpoints.down('sm')]: {
      paddingRight: '6%',
      paddingLeft: '6%',
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: '4%',
      paddingLeft: '4%',
      paddingTop: 33,
    },
  },
}));

export function OurWork() {
  const classes = useStyles();

  return (
    <div className={classes.ourWork}>
      <Typography variant="h3">Our Work</Typography>
      <Box pt={{ xs: 3, md: 14 }} />

      <Box>
        <Helmet>
          <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
        </Helmet>
        <iframe
          title="instagram images"
          src="//lightwidget.com/widgets/d3542ace58cb5dd3937f31ed06c53b9f.html"
          scrolling="no"
          allowtransparency="true"
          className="lightwidget-widget"
          style={{ width: '100%', border: 0, overflow: 'hidden' }}
        ></iframe>
      </Box>
      <Box pt={{ xs: 2, md: 9 }} />
      <Button
        variant="contained"
        color="primary"
        target="_blank"
        href="https://www.instagram.com/merloslandscaping/"
        // top menu takes screen space, so it's an ugly hack to compensate that space for the anchor scroll
        id="about-us"
      >
        View all
      </Button>
      <Box pt={{ xs: 6, sm: 8, md: 10 }} />
    </div>
  );
}
