import * as React from 'react';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import VectorSection5Object from '../images/vector-section5-object.webp';
import Stars from '../images/stars.webp';
import Testimonial from '../images/testimonial.webp';

const useStyles = makeStyles((theme) => ({
  ourClients: {
    backgroundColor: theme.palette.colors.lightPinkColor,
    textAlign: 'center',
    backgroundImage: `url(${VectorSection5Object})`,
    backgroundSize: '31%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    paddingRight: '20%',
    paddingLeft: '20%',
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '50%',
      paddingRight: '13%',
      paddingLeft: '13%',
    },
  },
  testimonialImage: {
    width: 192,
    [theme.breakpoints.down('xs')]: {
      width: 85,
    },
  },
  starsImage: {
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: 110,
    },
  },
  signatureText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
}));

export function OurClients() {
  const classes = useStyles();

  return (
    <div className={classes.ourClients}>
      <Box pt={{ xs: 5, md: 14 }} />
      <img src={Testimonial} alt="Testimonial" width={192} className={classes.testimonialImage} />
      <Box pt={{ xs: 2, md: 6 }} />
      <Typography variant="h3">Our Clients</Typography>
      <Box mt={{ xs: 1, md: 2 }} />
      <Typography variant="body2">Work with big and small</Typography>
      <Box pt={{ xs: 1, md: 3 }} />
      <Box pt={{ xs: 3, md: 6 }} />
      <Typography variant="subtitle2">
        “Amazing service, extremely high quality at a reasonable price. Merlos redid our driveway, walkway, and added
        new gardening beds. We couldn’t be happier.”
      </Typography>
      <Box pt={{ xs: 3, md: 7 }} />
      <Grid container spacing={1} justify="center" alignItems="center">
        <Grid item>
          <img src={Stars} alt="Stars" className={classes.starsImage} />
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.signatureText}>
            -Robert F.
          </Typography>
        </Grid>
      </Grid>
      <Box pt={{ xs: 4, md: 37 }} />
    </div>
  );
}
