import * as React from 'react';
import { Box, Button, makeStyles, Typography, Grid } from '@material-ui/core';
import HeroSection from '../images/hero-section.webp';

const useStyles = makeStyles((theme) => ({
  heroSection: {
    height: 650,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'center',
      height: 'auto',
    },
  },
  heroSectionBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '-100',
    backgroundImage: `url(${HeroSection})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    animationDuration: '60s',
    animationTimingFunction: 'linear',
    animationName: '$backgroundZoomNShift',
    animationIterationCount: 'infinite',
  },
  '@keyframes backgroundZoomNShift': {
    '0%': {
      backgroundPositionX: '0%',
      transform: 'scale(1)',
    },
    '50%': {
      backgroundPositionX: '10%',
      transform: 'scale(1.1)',
    },
    '100%': {
      backgroundPositionX: '00%',
      transform: 'scale(1)',
    },
  },
  heroSectionText: {
    paddingTop: 100,
    color: 'white',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '8% 2% 4%',
    },
  },
}));

export function HomeHeroSection() {
  const classes = useStyles();

  return (
    <div className={classes.heroSection}>
      <div className={classes.heroSectionBackground} src={HeroSection}></div>
      <Grid container alignItems="center" className={classes.heroSectionText}>
        <Grid item xs={12}>
          <Typography variant="h2">Creative Landscaping That Inspires</Typography>
        </Grid>
        <Box mt={3} width="100%" />
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.responsiveText15}>
            We’ve been re-Imagining Nantucket homes since 2001
          </Typography>
        </Grid>
        <Box mt={{ xs: 3, md: 7 }} width="100%" />
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" href="#about-us" style={{ marginRight: '2.5%' }}>
            About us
          </Button>
          <Button variant="contained" color="primary" href="/contact">
            Contact us
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
